import {createStore} from "vuex";
import {config} from "vuex-module-decorators";

// general stores from vo-application //
import authModule from "@/store/modules/authModule";
import bodyModule from "@/store/modules/bodyModule";
import breadcrumbsModule from "@/store/modules/breadcrumbsModule";
import configModule from "@/store/modules/configModule";
import historyModule from "@/store/modules/historyModule";

// module specific stores located inside module folder //
import usersStore from "@/modules/users/store";
import licensesStore from "@/modules/licenses/store";
import ordersStore from "@/modules/orders/store";
import inventoryStore from "@/modules/inventory/store";
import itemsStore from "@/modules/items/store";
import receivingStore from "@/modules/receiving/store";
import warehousesStore from "@/modules/warehouses/store";
import connectivityStore from "@/modules/connectivity/store";
import customersStore from "@/modules/customers/store";
import dashboardStore from "@/modules/dashboard/store";
import shipmentStore from "@/modules/shipment/store";

config.rawError = true;
const store = createStore({
    modules: {
        authModule,
        bodyModule,
        breadcrumbsModule,
        configModule,
        historyModule,
        usersStore,
        licensesStore,
        ordersStore,
        inventoryStore,
        itemsStore,
        receivingStore,
        warehousesStore,
        connectivityStore,
        customersStore,
        dashboardStore,
        shipmentStore,
    }
});

export default store;
